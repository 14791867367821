import './styles/app.scss';
import './styles/style.scss';
import './styles/main.css';
import 'animate.css';

const $ = require('jquery');
global.$ = global.jQuery = $;

require('@fortawesome/fontawesome-free/js/all.js');

import 'jquery-ui/ui/widgets/sortable';
import 'jquery-ui-bundle';
import 'slick-carousel';
import counterUp from 'counterup2'
import bulmaCarousel from 'bulma-carousel';


import AOS from 'aos';

import PatternLock from 'pattern-lock-js/patternlock.js';
import 'pattern-lock-js/patternlock.css';


AOS.init({
    // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
    offset: -300, // offset (in px) from the original trigger point
    delay: 0, // values from 0 to 3000, with step 50ms
    duration: 400, // values from 0 to 3000, with step 50ms
    easing: 'ease', // default easing for AOS animations
    once: false, // whether animation should happen only once - while scrolling down
    mirror: false, // whether elements should animate out while scrolling past them
    anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
  
  });

const callback = entries => {
	entries.forEach(entry => {
		const el = entry.target;
		if (entry.isIntersecting && !el.classList.contains('is-visible')) {
			counterUp(el, {
				duration: 6000,
				delay: 16,
			});
			el.classList.add('is-visible');
		}
	});
};

const IO = new IntersectionObserver(callback, { threshold: 1 });

const elements = document.querySelectorAll('.counter');
elements.forEach(el => {
	IO.observe(el);
});


bulmaCarousel.attach('#carousel-demo', {
    infinite:true,
    autoplay:true,
    autoplaySpeed: 20000,
});

$(".multiple-items").slick({
    slidesPerRow: 1,
    slidesToShow: 3,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    prevArrow: '',
    nextArrow: '',

    responsive: [{
   
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          infinite: true,
          dots: false,
        }
   
      }, {
   
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          dots: false,
          infinite: true
        }
   
      }, {
   
        breakpoint: 300,
        settings: "unslick" // destroys slick
   
      }]
  }).on('setPosition', function (event, slick) {
   slick.$slides.css('height', slick.$slideTrack.height() + 'px');
});


$('.allPrices').sortable({
    delay: 150,
    tolerance:"pointer",
    cursorAt: { top:0, left: 0 },
    stop: function(){
        let selectedData = new Array();
        $('.allPrices>tr').each(function (){
            selectedData.push($(this).data('id'));
        });
        updateTable(selectedData, 'preis');
    }
});

$('#deviceList').sortable({
    delay: 150,
    tolerance:"pointer",
    cursorAt: { top:0, left: 0 },
    stop: function(){
        let selectedData = new Array();
        $('#deviceList>tr').each(function (){
            selectedData.push($(this).data('id'));
        });
        updateTable(selectedData, 'geraet');
    }
});

function updateTable(data,type){

    $.post('/savePosition', {
        data: data,
        type: type
    }, (response) => {
        //console.log(response);
    });
}



let currentIndex = 0;
const items = document.querySelectorAll('.carousel-item');
const totalItems = items.length;
let slideInterval;

function showSlide(index) {
    const track = document.querySelector('.carousel-track');
    const offset = index * -100; // Berechnung des Offsets
    track.style.transform = `translateX(${offset}%)`;
}

function nextSlide() {
    currentIndex = (currentIndex + 1) % totalItems;
    showSlide(currentIndex);
    resetInterval(); // Intervall zurücksetzen
}

function prevSlide() {
    currentIndex = (currentIndex - 1 + totalItems) % totalItems;
    showSlide(currentIndex);
    resetInterval(); // Intervall zurücksetzen
}

function resetInterval() {
    clearInterval(slideInterval); // Altes Intervall löschen
    slideInterval = setInterval(nextSlide, 5000); // Neues Intervall starten
}

// Event Listener für Steuerungen
const rightControl = document.querySelector('.carousel-control.right');
const leftControl = document.querySelector('.carousel-control.left');

if (rightControl) {
    rightControl.addEventListener('click', nextSlide);
}

if (leftControl) {
    leftControl.addEventListener('click', prevSlide);
}




document.addEventListener('DOMContentLoaded', () => {





    // Prüfen, ob Cookies akzeptiert wurden
    var cookiesAccepted = localStorage.getItem("cookiesAccepted");

    // Überprüfen, ob die benötigten Elemente existieren
    var mapContainer = document.getElementById("mapContainer");
    var googleMap = document.getElementById("googleMap");
    var cookieMessage = document.getElementById("cookieMessage");

    if (cookiesAccepted === "true" && mapContainer && googleMap) {
        // Wenn Cookies akzeptiert wurden, zeige das Iframe und lade die Karte
        mapContainer.style.display = "block";
        googleMap.src = "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9640.416003519118!2d8.081685!3d52.838511!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x464c318069358bbf!2sSmartphone%20Service%20Kogorepair!5e0!3m2!1sde!2sus!4v1580159908766!5m2!1sde!2sus";
    } else if (cookieMessage) {
        // Wenn Cookies nicht akzeptiert wurden, zeige die Nachricht
        cookieMessage.style.display = "block";
    }


    // Get all "navbar-burger" elements
    const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

    // Add a click event on each of them
    $navbarBurgers.forEach( el => {
        el.addEventListener('click', () => {

            // Get the target from the "data-target" attribute
            const target = el.dataset.target;
            const $target = document.getElementById(target);

            // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
            el.classList.toggle('is-active');
            $target.classList.toggle('is-active');

        });
    });


    var cookieBanner = document.getElementById('cookie-banner');
    var acceptButton = document.getElementById('accept-cookies');
    var rejectButton = document.getElementById('reject-cookies');
    var cookieRevert = document.getElementById('cookieRevert'); // erneut öffnen

    // Überprüfen, ob der Benutzer den Cookie-Banner bereits akzeptiert oder abgelehnt hat
    var cookiesAccepted = localStorage.getItem('cookiesAccepted');

    // Wenn der Cookie-Banner noch nicht akzeptiert oder abgelehnt wurde, anzeigen
    if (cookiesAccepted === null) {
        cookieBanner.style.display = 'block';
    }

    // Eventlistener zum Akzeptieren der Cookies hinzufügen
    acceptButton.addEventListener('click', function() {
        // Cookie-Banner ausblenden
        cookieBanner.style.display = 'none';
        // Cookie-Zustimmung in localStorage speichern
        localStorage.setItem('cookiesAccepted', true);
        // Wenn Cookies akzeptiert wurden, zeige das Iframe und lade die Karte
        document.getElementById("mapContainer").style.display = "block";
        document.getElementById("cookieMessage").style.display = "none";
        document.getElementById("googleMap").src = "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9640.416003519118!2d8.081685!3d52.838511!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x464c318069358bbf!2sSmartphone%20Service%20Kogorepair!5e0!3m2!1sde!2sus!4v1580159908766!5m2!1sde!2sus";

    });

    // Eventlistener zum Ablehnen der Cookies hinzufügen
    rejectButton.addEventListener('click', function() {
        // Cookies löschen
        document.cookie.split(";").forEach(function(c) {
            document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
        });
        // Cookie-Banner ausblenden
        cookieBanner.style.display = 'none';
        // Cookie-Zustimmung in localStorage speichern (hier als "abgelehnt" markieren)
        localStorage.setItem('cookiesAccepted', false);
    });

    cookieRevert.addEventListener('click', function() {
        cookieBanner.style.display = 'block';
    });

    if (cookiesAccepted === 'true' || cookiesAccepted === 'false') {
        cookieBanner.style.display = 'none';
    } else {
        cookieBanner.style.display = 'block';
    }











    let entsperrmuster = document.getElementById("entsperrmuster");
let lockObj = document.getElementById("lock");

let lock = new PatternLock(lockObj, { // lockObj[0] für das echte DOM-Element
    onPattern: function (pattern) {
        entsperrmuster.value = 'M-' + pattern;
        console.log(pattern, entsperrmuster.value);
    }
});

function drawPattern(svgElement, pattern) {
    // Hilfsfunktionen
    function createMarker(x, y) {
        var marker = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
        marker.setAttribute('cx', x);
        marker.setAttribute('cy', y);
        marker.setAttribute('r', 6);
        marker.setAttribute('class', 'active-marker');
        return marker;
    }

    function createLine(x1, y1, x2, y2) {
        var line = document.createElementNS('http://www.w3.org/2000/svg', 'line');
        line.setAttribute('x1', x1);
        line.setAttribute('y1', y1);
        line.setAttribute('x2', x2);
        line.setAttribute('y2', y2);
        line.setAttribute('class', 'active-line');
        return line;
    }

    // Vorbereitung
    let dots = svgElement.querySelectorAll('.lock-dots circle'); // Punkte im SVG
    let linesContainer = svgElement.querySelector('.lock-lines'); // Linien-Container
    let activesContainer = svgElement.querySelector('.lock-actives'); // Marker-Container

    if (!dots || !linesContainer || !activesContainer) {
        console.error('SVG Struktur ist ungültig.');
        return;
    }

    // Vorherige Muster entfernen
    linesContainer.innerHTML = '';
    activesContainer.innerHTML = '';

    // Muster verarbeiten
    let patternPoints = pattern.toString().split('').map(num => dots[num - 1]);
    let lastPoint = null;

    patternPoints.forEach((point) => {
        if (!point) return;

        let cx = parseFloat(point.getAttribute('cx'));
        let cy = parseFloat(point.getAttribute('cy'));

        // Marker hinzufügen
        let marker = createMarker(cx, cy);
        activesContainer.appendChild(marker);

        // Linie hinzufügen (falls vorheriger Punkt existiert)
        if (lastPoint) {
            let line = createLine(
                parseFloat(lastPoint.getAttribute('cx')),
                parseFloat(lastPoint.getAttribute('cy')),
                cx,
                cy
            );
            linesContainer.appendChild(line);
        }

        lastPoint = point;
    });
}


$(document).on('click', '#showMuster', function (e) {
    e.preventDefault();
    let entsperrmusterInput = document.getElementById("entsperrmusterOrder");
    let musterContainer = document.getElementById("muster");
    if (entsperrmusterInput) {
        // Entferne den Präfix "M-" falls vorhanden
        entsperrmusterInput.value = entsperrmusterInput.value.replace(/^M-/, '');
    }

    let svgElement = document.querySelector('#lock');
    drawPattern(svgElement, entsperrmusterInput.value);

    musterContainer.style = 'display:block;';   
    console.log("test");     
});

});


